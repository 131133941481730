import React, { useState, useEffect } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import classNames from 'classnames';
import useBreakpoint from '../../../hooks/useBreakpoints';
import { useLocation, Link as RouterLink, withRouter } from 'react-router-dom';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';

//CSS
import styles from './home.module.css';

//IMAGES
import routes from '../../../constants/routes';
import ReactGA from 'react-ga';
import config from '../../../utils/config';
import TagManager from 'react-gtm-module';

function Header(props) {
  const { history } = props;
  const point = useBreakpoint();
  const location = useLocation();
  const [expanded, setExpanded] = useState(false);
  const [showMobile, setShowMobile] = useState(false)
  const [showDesktop,setShowDesktop] = useState(true)
  const [showHeader, setShowHeader] = useState(true)

  useEffect(() => {
    ReactGA.initialize(config.gaID);
    TagManager.initialize({
      gtmId: config.gtmID,
    });
    console.log(window.innerWidth)
    if (window.innerWidth < 650) {
      setShowMobile(true)
      setShowDesktop(false)
    }
     if (window.location.pathname === "/login" || window.location.pathname === "/abrir-tienda" ) {
      setShowHeader(false)
     }
     console.log()
  }, [showMobile]);

  useEffect(
    () => {
      ReactGA.pageview(window.location.pathname + window.location.search);
    },
    [location.pathname]
  );
  // const [mobileMenuOpen, setMobileMenuOpen] = (false)
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  return (
    <div>
    {showHeader && 
    <Navbar
      expand="md"
      className={classNames(styles.NavBar, 'navbar fixed-top')}
    >
      <Navbar.Brand href={routes.home}>
        <img width="auto" height="auto"
          src="https://s3.amazonaws.com/lumarketo.cl/logo-lumarketo-franciscus.svg"
          alt="logo"
          className={classNames(styles.Logo, 'd-inline-block align-top')}
        />
      </Navbar.Brand>
{ showMobile && 
<div> 
      <img alt="lumarketo burger icon" onClick={toggleDrawer("right", true)} src="https://s3.amazonaws.com/lumarketo.cl/burger-tablet-mobile-menu.svg" />
      <Drawer anchor={"right"}
        open={state["right"]}
        onClose={toggleDrawer("right", false)}
      >
        <div className='containerSideBar'>
        <a href="/plantillas">   <p className='menuItemSideBar'>Plantillas</p></a>

            <a href="/beneficios">   <p className='menuItemSideBar'>Beneficios</p></a>
          
           <a>  <p className='menuItemSideBar'>Contáctanos</p></a>   
         
          <a href="/precio">   <p className='menuItemSideBar'>Precio</p></a>  
        
         <a href="/blog">     <p className='menuItemSideBar'>Blog</p></a> 
    
          </div>
         
      </Drawer>
      </div>
    }
      <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
        <Nav className="ml-auto">
          {/* <Nav.Link
            type={RouterLink}
            onClick={() => history.push (routes.services)}
            className={classNames (
              styles.NavItem,
              location.pathname === routes.services ? styles.Lnea3 : ''
            )}
          >
            Servicios
          </Nav.Link> */}
            <Nav.Link
            type={RouterLink}
            onClick={() => history.push(routes.themes)}
            className={classNames(
              styles.NavItem,
              location.pathname === routes.themes ? styles.Lnea3 : ''
            )}
          >
            Plantillas
          </Nav.Link>
          <Nav.Link
            type={RouterLink}
            onClick={() => history.push(routes.benefits)}
            className={classNames(
              styles.NavItem,
              location.pathname === routes.benefits ? styles.Lnea3 : ''
            )}
          >
            Beneficios
          </Nav.Link>
          {/* <Nav.Link
            type={RouterLink}
            onClick={() => history.push (routes.plans)}
            className={classNames (
              styles.NavItem,
              location.pathname === routes.plans ? styles.Lnea3 : ''
            )}
          >
            Planes
          </Nav.Link> */}
          {/* <Nav.Link
            type={RouterLink}
            onClick={() => history.push(routes.contactUs)}
            className={classNames(
              styles.NavItem,
              location.pathname === routes.contactUs
                ? styles.ActiveBorderNavItem
                : ''
            )}
          >
            Contáctanos
          </Nav.Link> */}
          <Nav.Link
            type={RouterLink}
            onClick={() => history.push(routes.priceNew)}
            className={classNames(
              styles.NavItem,
              location.pathname === routes.priceNew
                ? styles.ActiveBorderNavItem
                : ''
            )}
          >
            Precio
          </Nav.Link>
          <Nav.Link
            type={RouterLink}
            onClick={() => history.push(routes.blogindex)}
            className={classNames(
              // styles.buttonHeader,
              styles.NavItem,
              location.pathname === routes.prices
                ? styles.ActiveBorderNavItem
                : ''
            )}
          >
            Blog
          </Nav.Link>
        </Nav>
        {showDesktop && 
        <Nav className='rightBottoms'>
        <Nav.Link className='contactBottom'
        type={RouterLink}
        onClick={() => history.push(routes.login)}
        >
          Iniciar sesión 
        </Nav.Link>
        <Nav.Link className='freeBottom'
        type={RouterLink}
        onClick={() => history.push(routes.demo)}
        >

          Prueba Gratis
        </Nav.Link>
        </Nav>}
      </Navbar.Collapse>
    </Navbar>}</div>
  );
}

export default withRouter(Header);
