import React from 'react';
import {Spinner} from 'react-bootstrap';
import styles from './styles.module.css';

export default function Loading () {
  return (
    <div className={styles.Container}>
      <Spinner animation="grow" variant="dark" />
    </div>
  );
}
