import React, {useEffect, useState} from 'react';
import {Row, Col} from 'react-bootstrap';
import classNames from 'classnames';
import useBreakpoints from '../../../hooks/useBreakpoints';
//CSS
import styles from './home.module.css';

//IMAGES
import lgLogo from '../../../assets/logo-lg.png';
import smLogo from '../../../assets/logo-sm.png';
import inSVG from '../../../assets/linkedin.svg';
import facebookSVG from '../../../assets/facebook.svg';

function Footer (props) {
  const point = useBreakpoints ();
  const [showFooter, setShowFooter] = useState(true)

  const getLogo = () => {
    switch (point) {
      case 'sm':
        return smLogo;
      case 'lg':
        return lgLogo;
      default:
        return lgLogo;
    }
  };
  useEffect(() => {

     if (window.location.pathname === "/login" || window.location.pathname === "/abrir-tienda") {
      setShowFooter(false)
     }

  },[]);

  return (
    <div> {showFooter && 
    <footer id="footer" className={classNames (styles.Footer, 'text-muted')}>
      <Row>
        <Col lg={4} md={4} sm={12}>
          <img src={getLogo ()} alt="Logo" className={styles.FooterLogo} />
        </Col>
        <Col lg={6} md={5} sm={12}>
          <h4 className={styles.Telfono}>
            Teléfono
          </h4>
          
          <h4 className={styles.VenezuelaChile}>
            Chile +56 958765629
          </h4>
        </Col>
        <Col lg={2} md={3} sm={12}>
          <div className={styles.svgContainer}>
            <div className={styles.svg}>
              <img src={facebookSVG} alt="facebook" className={styles.svgImg} />
            </div>
            <div className={styles.svg}>
              <img src={inSVG} alt="linkedin" className={styles.svgImg} />
            </div>
          </div>

        </Col>
        <Col lg={12} className={styles.Lnea2} />
        <Col
          lg={8}
          md={8}
          sm={12}
          style={{display: point === 'sm' ? 'inline-flex' : ''}}
        >
          <h4 className={styles.Lumarketo2021}>
            Lumarketo 2021
          </h4>
          {point === 'sm' &&
            <h4 className={styles.TrminosPrivacidad}>
              Términos   ·    Privacidad
            </h4>}
        </Col>
        {point !== 'sm' &&
          <Col lg={4} md={4} sm={6}>
            <h4 className={styles.TrminosPrivacidad}>
              Términos   ·    Privacidad
            </h4>
          </Col>}
      </Row>

    </footer>}
    </div>
  );
}

export default Footer;
