import React, {lazy} from 'react';
import {Route} from 'react-router';
import routes from '../constants/routes';

const ShippingPage = lazy (() => import ('../containers/pages/shipping/shipping.jsx'));

function ShippingRoute () {
  return <Route exact path={routes.shipping} component={ShippingPage} />;
}

export default ShippingRoute;