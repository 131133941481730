export default {
  services: "/servicios",
  home: "/",
  plans: "/planes",
  plan: "/plan",
  contactUs: "/contactanos",
  demo: "/abrir-tienda",
  prices:"/precios",
  benefits:"/beneficios",
  inventory:"/sistema-de-inventario",
  support: "/canal-de-soporte",
  shipping: "/canales-de-envio",
  tires: "/tires-example",
  mercadopago:"/mercadopago",
  saas:"/blog/saas",
  blog:"/blog/la-mejor-plataforma",
  inventoryBlog:"/blog/inventario-automatizado",
  successEcom:"/blog/ecommerce-exitoso",
  ecommerce:"/blog/ecommerce",
  news:"/blog/actualidad-ecommerce",
  homeNew:"/home",
  editor:"/crea-la-tienda",
  priceNew:"/precio",
  themes:"/plantillas",
  login:"/login",
  checkoutree:"/checkout-tree",
  blogindex:"/blog"

};
