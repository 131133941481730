import React, {lazy} from 'react';
import {Route} from 'react-router';
import routes from '../constants/routes';

const SucceesEcomm = lazy (() => import ('../containers/pages/successEcomm/successEcom.jsx'));

function SuccessEcommRoute () {
  return <Route exact path={routes.successEcom} component={SucceesEcomm} />;
}

export default SuccessEcommRoute;