import React, {lazy} from 'react';
import {Route} from 'react-router';
import routes from '../constants/routes';


const Editor = lazy (() => import ('../containers/pages/editor/editor.jsx'));

function EditorRoute () {
  return <Route exact path={routes.editor} component={Editor} />;
}

export default EditorRoute;