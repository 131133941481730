import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import * as serviceWorker from './serviceWorker';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-MGHFZ52'
}
TagManager.initialize(tagManagerArgs)


ReactDOM.render(
  <Suspense fallback={<div />}>
    <App />
  </Suspense>,
  document.getElementById('root')
);
serviceWorker.unregister();
