const config = {
  companyMicroserviceUrlApi: process.env.REACT_APP_COMPANY_API_URL,
  demoMicroserviceUrlApi: process.env.REACT_APP_DEMO_API_URL,
  initCompanyApi: process.env.REACT_APP_INIT_COMPANY_API_URL,
  gaID: process.env.REACT_APP_GA_ID,
  gtmID: process.env.REACT_APP_GTM_ID,
  googlePlacesKey: process.env.REACT_APP_PLACES_GOOGLE_API_KEY,
  jwtSecret: process.env.REACT_APP_JWT_SECRET,
};

export default config;
