import React, {lazy} from 'react';
import {Route} from 'react-router';
import routes from '../constants/routes';

const Plantillas = lazy (() => import ('../containers/pages/themes/themes.jsx'));

function ThemesRoute () {
  return <Route exact path={routes.themes} component={Plantillas} />;
}

export default ThemesRoute;