import React, {lazy} from 'react';
import {Route} from 'react-router';
import routes from '../constants/routes';


const Price = lazy (() => import ('../containers/pages/priceNew/priceNew.jsx'));

function PriceNewRoute () {
  return <Route exact path={routes.priceNew} component={Price} />;
}

export default PriceNewRoute;