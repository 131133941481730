import React, {lazy} from 'react';
import {Route} from 'react-router';
import routes from '../constants/routes';

const SupportPage = lazy (() => import ('../containers/pages/support/soporte.jsx'));

function SupportRoute () {
  return <Route exact path={routes.support} component={SupportPage} />;
}

export default SupportRoute;