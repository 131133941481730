import React, {lazy} from 'react';
import {Route} from 'react-router';
import routes from '../constants/routes';

const HomePage = lazy (() => import ('../containers/pages/home/home'));
const HomePage2 = lazy(() => import ('../containers/pages/homeNew/homeNew') )

function HomeRoute () {
  return <Route exact path={routes.home} component={HomePage2} />;
}

export default HomeRoute;
