import React, {lazy} from 'react';
import {Route} from 'react-router';
import routes from '../constants/routes';

const HomePageNew = lazy (() => import ('../containers/pages/homeNew/homeNew'));

function HomeRouteNew () {
  return <Route exact path={routes.homeNew} component={HomePageNew} />;
}

export default HomeRouteNew;
