import React, {lazy} from 'react';
import {Route} from 'react-router';
import routes from '../constants/routes';

const Page = lazy (() => import ('../containers/pages/plans'));
const ViewPage = lazy (() => import ('../containers/pages/plans/view'));

function PlansRoute () {
  return <Route exact path={routes.plans} component={Page} />;
}

export function ViewRoute () {
  return <Route exact path={routes.plan} component={ViewPage} />;
}

export default PlansRoute;
