import React, { Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";

import App from "../containers/pages/app";
import LoadingPage from "../components/loading/spinner";

import HomeRoute from "./home";
import PricePage from './prices'
import ServicesRoute from "./services";
import PlansRoute, { ViewRoute } from "./plans";
import ContactUsPage from "./contactUs";
import DemoPage from "./demo";
import Benefits from './benefits';
import InventoryRoute from "./inventory";
import SupportRoute from "./support";
import ShippingRoute  from "./shipping";
import TiresRoute from "./tiresChile";
import MercadopagoRoute  from "./mercadopago";
import SaasRoute  from "./saas";
import BlogIndexRoute from "./blogindex";
import NewsRoute from "./news";
import BlogInventoryRoute from "./blogInventory";
import SuccessEcommRoute from "./successEcomm";
import Ecommerce from "./ecommerce";
import HomeRouteNew from "./homeNew";
import EditorRoute from "./editor";
import PriceNewRoute from "./priceNew";
import ThemesRoute from "./themes";
import LoginRoute from "./login";
import Checkoutree from "./checkoutree";
import ScrollToTop from "../components/scrollToTop/scrollToTop";


function Routes(props) {
  return (
    <Router>
      <App {...props}>
        <Suspense fallback={<LoadingPage />}>
          <DemoPage />
          <ContactUsPage />
          <Checkoutree/>
          <HomeRoute />
          <BlogIndexRoute/>
          <LoginRoute/>
          <BlogInventoryRoute/>
          <ServicesRoute />
          <Ecommerce/>
          <ThemesRoute/>
          <HomeRouteNew/>
          <SuccessEcommRoute/>
          <PlansRoute />
          <PricePage />
          <ViewRoute />
          <SaasRoute/>
          <NewsRoute/>
          <Benefits />
          <PriceNewRoute/>
          <EditorRoute/>
          <MercadopagoRoute />
          <TiresRoute/>
          <InventoryRoute/>
          <SupportRoute />
          <ShippingRoute />
        </Suspense>
      </App>
      <ScrollToTop />
    </Router>
  );
}

export default Routes;
