import React, {lazy} from 'react';
import {Route} from 'react-router';
import routes from '../constants/routes';

const Checkoutree = lazy (() => import ('../containers/pages/checkoutree/checkoutree.jsx'));

function CheckoutreeRoute () {
  return <Route exact path={routes.checkoutree} component={Checkoutree} />;
}

export default CheckoutreeRoute;