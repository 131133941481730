import React, {lazy} from 'react';
import {Route} from 'react-router';
import routes from '../constants/routes';

const Saas = lazy (() => import ('../containers/pages/saas/saas.jsx'));

function SaasRoute () {
  return <Route exact path={routes.saas} component={Saas} />;
}

export default SaasRoute;