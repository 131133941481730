import React, {lazy} from 'react';
import {Route} from 'react-router';
import routes from '../constants/routes';

const OpenStore = lazy (() => import ('../containers/pages/openStore/openStore.jsx'));

function HomeRoute () {
  return <Route exact path={routes.demo} component={OpenStore} />;
}

export default HomeRoute;
