import React, {lazy} from 'react';
import {Route} from 'react-router';
import routes from '../constants/routes';

const Ecommerce = lazy (() => import ('../containers/pages/ecommerce/ecommerce.jsx'));

function EcommerceRoute () {
  return <Route exact path={routes.ecommerce} component={Ecommerce} />;
}

export default EcommerceRoute;