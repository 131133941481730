import React, {lazy} from 'react';
import {Route} from 'react-router';
import routes from '../constants/routes';

const Login = lazy (() => import ('../containers/pages/login/login.jsx'));

function LoginRoute () {
  return <Route exact path={routes.login} component={Login} />;
}

export default LoginRoute;