import React, {lazy} from 'react';
import {Route} from 'react-router';
import routes from '../constants/routes';

const TiresExample = lazy (() => import ('../containers/pages/tiresChile/tires.jsx'));

function TiresRoute () {
  return <Route exact path={routes.tires} component={TiresExample} />;
}

export default TiresRoute;