import React, {lazy} from 'react';
import {Route} from 'react-router';
import routes from '../constants/routes';

const BlogInventory = lazy (() => import ('../containers/pages/blogInventory/blogInventory.jsx'));

function BlogInventoryRoute () {
  return <Route exact path={routes.inventoryBlog} component={BlogInventory} />;
}

export default BlogInventoryRoute;