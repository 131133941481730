import React, {lazy} from 'react';
import {Route} from 'react-router';
import routes from '../constants/routes';

const News = lazy (() => import ('../containers/pages/news/news.jsx'));

function NewsRoute () {
  return <Route exact path={routes.news} component={News} />;
}

export default NewsRoute;