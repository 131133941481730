import React, {lazy} from 'react';
import {Route} from 'react-router';
import routes from '../constants/routes';

const Mercadopago = lazy (() => import ('../containers/pages/mercadopago/mercadopago.jsx'));

function MercadopagoRoute () {
  return <Route exact path={routes.mercadopago} component={Mercadopago} />;
}

export default MercadopagoRoute;