import React, {lazy} from 'react';
import {Route} from 'react-router';
import routes from '../constants/routes';

const Benefits = lazy (() => import ('../containers/pages/benefits/benefits.jsx'));

function BenefitsRoute () {
  return <Route exact path={routes.benefits} component={Benefits} />;
}

export default BenefitsRoute;