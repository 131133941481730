import React, {lazy} from 'react';
import {Route} from 'react-router';
import routes from '../constants/routes';

const PricePage = lazy (() => import ('../containers/pages/prices/prices.jsx'));

function HomeRoute () {
  return <Route exact path={routes.prices} component={PricePage} />;
}

export default HomeRoute;