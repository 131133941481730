import React, {lazy, Fragment} from 'react';

//IMPORTS
import Header from '../home/header'
import Footer from  '../home/footer' 
import FooterNew from '../../../components/footer/footer';

function App (props) {
  return (
    <Fragment>
      <Header {...props} />
      <main>
        <div className="bodyContainer">
          <Fragment>{props.children}</Fragment>
        </div>
      </main>
      <FooterNew />
    </Fragment>
  );
}

export default App;
