import React, {lazy} from 'react';
import {Route} from 'react-router';
import routes from '../constants/routes';

const BlogIndex = lazy (() => import ('../containers/pages/blogIndex/blogindex.jsx'));

function BlogIndexRoute () {
  return <Route exact path={routes.blogindex} component={BlogIndex} />;
}

export default BlogIndexRoute;