import React, {lazy} from 'react';
import {Route} from 'react-router';
import routes from '../constants/routes';

const ServicesPage = lazy (() => import ('../containers/pages/services'));

function ServicesRoute () {
  return <Route exact path={routes.services} component={ServicesPage} />;
}

export default ServicesRoute;
