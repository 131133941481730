import React, {lazy} from 'react';
import {Route} from 'react-router';
import routes from '../constants/routes';

const Page = lazy (() => import ('../containers/pages/contactUs'));

function ContactUsRoute () {
  return <Route exact path={routes.contactUs} component={Page} />;
}

export default ContactUsRoute;
