import React , {useEffect, useState} from 'react'
import styles from './footer.css';

const FooterNew = () => {
    const [showFooter, setShowFooter] = useState(true)
    useEffect(() => {

        if (window.location.pathname === "/login" || window.location.pathname === "/abrir-tienda") {
         setShowFooter(false)
        }
   
     },[]);
    return (
        <div> {showFooter && 
        <div className="containerFooter">
            <div className="containerColumns">
                <div className="footerColum">
                    <p className="columnTitle">Tienda en línea</p>
                    <a href="/"><p className="columnSubtitle">Galeria de plantillas</p> </a>
                    <a href="/"><p className="columnSubtitle">Motor de edición</p> </a>
                    <a href="/"><p className="columnSubtitle">Vender en Línea</p> </a>
                    <a href="/"><p className="columnSubtitle">Vender en instagram</p> </a>
                    <a href="/"><p className="columnSubtitle">Gestiona tu negocio</p> </a>
                    <a href="/"><p className="columnSubtitle">Migrar a Lumarketo</p> </a>
                </div>
                <div className="footerColum">
                    <p className="columnTitle">Envíos</p>
                    <a href="/"><p className="columnSubtitle">Conexión con dark stores</p> </a>
                    <a href="/"><p className="columnSubtitle">Gestión automática</p> </a>
                    <a href="/"><p className="columnSubtitle">Métodos de envio</p> </a>


                </div>
                <div className="footerColum">
                    <p className="columnTitle">Pasarela de pagos</p>
                    <a href="/"><p className="columnSubtitle">MercadoPago</p> </a>
                    <a href="/"><p className="columnSubtitle">Configuración de cuotas sin interés</p> </a>
                    <a href="/"><p className="columnSubtitle">Pago con tarjetas de débito
                        o crédito</p> </a>
                    <a href="/"><p className="columnSubtitle">Pago seguro</p> </a>
                    <a href="/"><p className="columnSubtitle">Checkoutree</p> </a>
                </div>
                <div className="footerColum">
                    <p className="columnTitle">Lumarketo</p>
                    <a href="/"><p className="columnSubtitle">Sobre nosotros</p> </a>
                    <a href="/"><p className="columnSubtitle">Contacto</p> </a>
                    <a href="/"><p className="columnSubtitle">Blog</p> </a>
                    <a href="/"><p className="columnSubtitle">Centro de ayuda de caso</p> </a>


                </div>
            </div>
            <div className="newsletterContainer">
                <div className="newsletterBox">
                    <div>
                        <p className="newsletterTitle">Suscribete a nuestro Newsletter</p>
                    </div>
                    <div className="inputFlex">
                        <input className="inputNewsletter" placeholder="Ingrese su correo electrónico" />
                        <button className="buttonNewsletter">Suscribir</button>
                    </div>
                    <div>
                        <p className="iconsColumn">
                            <a target="_blank" href="https://www.youtube.com/">
                                <img className="iconSocial" src="https://s3.amazonaws.com/lumarketo.cl/icono-footer-youtube.svg" alt="youtube lumarketo" />
                            </a>
                            <a target="_blank" href="https://www.instagram.com/lumarketo/">
                                <img className="iconSocial" src="https://s3.amazonaws.com/lumarketo.cl/icono-footer-instagram.svg" alt="instagram lumarketo" />
                            </a>
                            <a target="_blank" href="https://www.instagram.com/lumarketo/">
                                <img className="iconSocial" src="https://s3.amazonaws.com/lumarketo.cl/icono-footer-facebook.svg" alt="facebook lumarketo" />
                            </a>

                        </p>
                    </div>
                </div>
            </div>

        </div>}
        </div>
    )
}
export default FooterNew